<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : 'pc'}`">
    <pop-cropper
      :show="cropperShow"
      :img="cropperImg"
      @close="cropperClose"
      @submit="cropperSubmit"
    ></pop-cropper>
    <van-calendar
      v-model="calendarShow"
      :position="$utils.isMobile().mobile ? 'bottom' : 'bottom'"
      @confirm="calendarConfirm"
    />
    <van-popup
      v-model="timeShow"
      :position="$utils.isMobile().mobile ? 'bottom' : 'center'"
      round
    >
      <van-datetime-picker
        :visible-item-count="$utils.isMobile().mobile ? 8 : 16"
        v-model="currentTime"
        type="time"
        title="选择时间"
        @confirm="timeConfirm"
        @cancel="timeCancel"
      />
    </van-popup>
    <action-confirm
      :show="bgMusicTipsShow"
      :submitText="'去上传音乐'"
      @close="bgMusicTipsClose"
      @submit="bgMusicTipsSubmit"
    >
      <div class="action-confirm-tips">
        <p>可选择资源库的音乐作为活动背景音乐，进入活动后自动播放</p>
      </div>
    </action-confirm>
    <pop-top :black="true"></pop-top>
    <div class="title">
      <div class="left">
        <span>{{ editId ? "编辑活动" : "创建活动" }}</span>
        <span>/ {{ editId ? "Edit" : "Create" }} Activity</span>
      </div>
    </div>
    <div class="info">
      <div class="info-item">
        <div class="left">
          <span>活动主题</span>
        </div>
        <div class="right">
          <input
            class="item-input"
            v-model="title"
            placeholder="请输入活动主题"
          />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>活动封面</span>
        </div>
        <div class="right">
          <div
            :class="`item-cover cursor-pointer ${
              k == coverIndex ? 'active' : ''
            }`"
            v-for="(i, k) in cover"
            :key="k"
            @click="coverClick(k)"
          >
            <img :src="i" alt="" />
            <div
              class="icon-upload-wrapper cursor-pointer"
              v-if="isUploaded && k == cover.length - 1"
            >
              <van-uploader :after-read="afterRead">
                <div class="icon-upload"></div>
              </van-uploader>
            </div>
          </div>
          <van-uploader :after-read="afterRead" v-if="!isUploaded">
            <div class="item-upload cursor-pointer">
              <div class="icon-upload"></div>
              <span>点击上传</span>
            </div>
          </van-uploader>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>活动时间</span>
        </div>
        <div class="right">
          <div class="time-wrapper cursor-pointer" @click="calendarOpen(0)">
            <span>{{ startDate }}</span>
            <div class="icon-calendar"></div>
          </div>
          <div class="time-wrapper cursor-pointer" @click="timeOpen(0)">
            <span>{{ startTime }}</span>
            <div class="icon-time"></div>
          </div>
          <span class="time-split">{{
            $utils.isMobile().mobile ? "至" : "——"
          }}</span>
          <div class="time-wrapper cursor-pointer" @click="calendarOpen(1)">
            <span>{{ endDate }}</span>
            <div class="icon-calendar"></div>
          </div>
          <div class="time-wrapper cursor-pointer" @click="timeOpen(1)">
            <span>{{ endTime }}</span>
            <div class="icon-time"></div>
          </div>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>并发人数</span>
        </div>
        <div class="right">
          <!-- <div class="icon-minus cursor-pointer" @click="doCount(0)"></div> -->
          <span class="count-number">{{ count }}</span>
          <!-- <div class="icon-add cursor-pointer" @click="doCount(1)"></div> -->
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>活动空间</span>
        </div>
        <div
          class="right flex-column width-full overflow-x-hidden flex-align-left"
        >
          <div
            class="right flex-column width-full overflow-x-hidden flex-align-left"
            v-for="(m, n) in scene"
            :key="n"
          >
            <p class="scene-type-name">{{ m.name }}</p>
            <div class="right width-full">
              <div
                :class="`item-wrapper cursor-pointer`"
                v-for="(i, k) in m.data"
                :key="k"
                @click="sceneClick(i, k)"
              >
                <div
                  :class="`item-cover  ${
                    sceneChooseIds.includes(i.id) ? 'active' : ''
                  }`"
                >
                  <img :src="i.cover" alt="" />
                </div>
                <span class="item-label">{{ i.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>背景音乐</span>
        </div>
        <div class="right">
          <select v-model="bgMusicChoose" class="item-input">
            <option v-for="(i, k) in bgMusicList" :key="k" :value="i.id">
              {{ i.name }}
            </option>
            <option value="">无</option>
          </select>
          <div
            class="icon-tips cursor-pointer"
            @click.stop.prevent="bgMusicTipsOpen"
          ></div>
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>活动密码</span>
        </div>
        <div class="right">
          <input
            class="item-input"
            v-model="password"
            @keyup="password = password.replace(/[^\d]/g, '')"
            maxlength="6"
            placeholder="请输入4-6位数字密码，不输入则无需密码"
          />
        </div>
      </div>
      <div class="info-action">
        <div class="back cursor-pointer" @click="back">
          <span>返回</span>
        </div>
        <div class="action cursor-pointer" @click="submit">
          <span>{{ editId ? "保存" : "创建" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert, routesData } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopTop from "@/components/PopTop.vue";
import PopCropper from "@/components/PopCropper.vue";
import ActionConfirm from "@/components/common/ActionConfirm.vue";

export default {
  components: { PopTop, PopCropper, ActionConfirm },
  data() {
    const now = Date.now();
    const date = this.$utils.timestampToTime(now).substring(0, 11);
    const time = this.$utils.timestampToTime(now).substring(11, 16);
    const tomorrow = this.$utils
      .timestampToTime(now + 86400000)
      .substring(0, 11);
    return {
      href: location.href,
      title: "",
      startDate: date,
      startTime: time,
      endDate: tomorrow,
      endTime: time,
      count: 5,
      password: "",
      cover: [],
      coverIndex: 0,
      scene: [],
      sceneChooseIds: [],
      isUploaded: false,
      uploadFile: {},
      calendarShow: false,
      currentCalendarType: false,
      currentTime: "00:00",
      timeShow: false,
      currentTimeType: false,
      editId: "",
      cropperShow: false,
      bgMusicList: [],
      bgMusicChoose: "",
      bgMusicTipsShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    wxShare({});
    if (!this.userInfo) {
      // 未登录
      this.$router.replace({
        name: routesData.index.name,
      });
    }
    if (this.$route.query && this.$route.query.id) {
      this.editId = this.$route.query.id;
      sessionStorage.setItem("activity_id", this.editId);
    }
    this.getBgMusicList();
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    bgMusicTipsOpen() {
      this.bgMusicTipsShow = true;
    },
    bgMusicTipsClose() {
      this.bgMusicTipsShow = false;
    },
    bgMusicTipsSubmit() {
      const routeUrl = this.$router.resolve({
        name: routesConvert(routesModuleData.manage, true).name,
        query: {
          tab: "resource",
        },
      });
      window.open(routeUrl.href, "_blank");
      this.bgMusicTipsClose();
    },
    async getBgMusicList() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载背景音乐中...",
      });
      const { code, data } = await this.$http.get("/resource/list-no-page", {
        type: 1,
      });
      this.$toast.clear();
      code == 200 && data && data.length > 0 && (this.bgMusicList = [...data]);
      this.getInfo();
    },
    cropperSubmit(imgUrl) {
      if (imgUrl) {
        if (this.isUploaded) {
          this.coverIndex = -1;
          this.cover.pop();
          this.cover.push(imgUrl);
        } else {
          this.cover.push(imgUrl);
        }
        this.coverIndex = this.cover.length - 1;
        this.isUploaded = true;
        this.$toast.success("上传成功");
        this.cropperShow = false;
      }
    },
    cropperClose() {
      this.cropperShow = false;
    },
    async submit() {
      if (!this.title) {
        this.$toast.fail("活动主题不能为空");
        return;
      }
      if (!this.count || this.count != 5) {
        this.$toast.fail("并发人数不可修改");
        return;
      }
      try {
        if (
          Date.parse(this.startDate + " " + this.startTime + ":00") >=
          Date.parse(this.endDate + " " + this.endTime + ":00")
        ) {
          this.$toast.fail("开始时间不得超过结束时间");
          return;
        }
      } catch (e) {
        this.$toast.fail("时间格式有误");
        return;
      }
      if (this.sceneChooseIds.length == 0) {
        this.$toast.fail("至少要有1个活动空间");
        return;
      }
      if (this.password) {
        if (!(this.password.length >= 4 && this.password.length <= 6)) {
          this.$toast.fail("活动密码只能4-6位数字");
          return;
        }
      }
      const sceneIds = [];
      this.sceneChooseIds.map((i) => {
        sceneIds.push({
          activity_scene_id: i,
          name: "",
        });
      });
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.editId ? "修改活动中..." : "创建活动中...",
      });
      let bgMusicSubmit = [];
      if (this.bgMusicChoose) {
        // 选择了背景音乐
        this.bgMusicList.map((i) => {
          if (i.id == this.bgMusicChoose) {
            bgMusicSubmit["url"] = i.url;
            bgMusicSubmit["duration"] = i.duration;
          }
        });
      }
      let result = {};
      if (this.editId) {
        result = await this.$http.post("/activity/update", {
          id: this.editId,
          title: this.title,
          cover: this.cover[this.coverIndex],
          password: this.password,
          background_music: bgMusicSubmit,
        });
      } else {
        result = await this.$http.post("/activity/create", {
          title: this.title,
          cover: this.cover[this.coverIndex],
          start_time: this.startDate + " " + this.startTime + ":00",
          end_time: this.endDate + " " + this.endTime + ":00",
          limit_people: this.count,
          scene_id: sceneIds,
          password: this.password,
          background_music: bgMusicSubmit,
        });
      }
      if (result.code == 200) {
        this.$toast.success(this.editId ? "活动修改成功" : "活动创建成功");
        setTimeout(() => {
          this.$router.replace({
            name: routesConvert(routesModuleData.manage, true).name,
          });
        }, 300);
      } else {
        this.$toast.fail(
          (this.editId ? "活动修改失败：" : "活动创建失败：") + result.msg
        );
      }
    },
    timeOpen(type) {
      if (this.editId) {
        this.$toast.fail("活动时间不可修改");
        return;
      }
      this.currentTimeType = type;
      if (!type) {
        this.currentTime = this.startTime;
      } else {
        this.currentTime = this.endTime;
      }
      this.timeShow = true;
    },
    timeConfirm() {
      console.log(this.currentTimeType, "currentTimeType");
      if (!this.currentTimeType) {
        this.startTime = this.currentTime;
      } else {
        this.endTime = this.currentTime;
      }
      this.timeShow = false;
    },
    timeCancel() {
      this.timeShow = false;
    },
    calendarOpen(type) {
      if (this.editId) {
        this.$toast.fail("活动时间不可修改");
        return;
      }
      this.currentCalendarType = type;
      this.calendarShow = true;
    },
    calendarConfirm(date) {
      if (!this.currentCalendarType) {
        this.startDate = this.$utils
          .timestampToTime(date.getTime())
          .substring(0, 11);
      } else {
        this.endDate = this.$utils
          .timestampToTime(date.getTime())
          .substring(0, 11);
      }
      this.calendarShow = false;
    },
    doCount(type) {
      if (this.count <= 1 && !type) {
        this.$toast.fail(`人数不可少于${this.count}人哟~`);
        return;
      }
      if (this.count >= 10 && type) {
        this.$toast.fail(`人数不可超过${this.count}人哟~`);
        return;
      }
      this.count += type ? 1 : -1;
    },
    sceneClick(i) {
      if (this.editId) {
        this.$toast.fail("活动空间不可修改");
        return;
      }
      if (this.sceneChooseIds.includes(i.id)) {
        const list = [];
        this.sceneChooseIds.map((m) => {
          if (m != i.id) {
            list.push(m);
          }
        });
        this.sceneChooseIds = list;
      } else {
        this.sceneChooseIds.push(i.id);
      }
    },
    coverClick(k) {
      this.coverIndex = k;
    },
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file, "file");
      // this.uploadFile = file;
      // const form = new FormData();
      // form.append("file_name", file.file);
      // const result = await this.$http.uploads(`/common/ali-cloud/upload`, form);
      let imgUrl = file.content;
      // if (result.data.code === 200 && result.data.data) {
      //   imgUrl = result.data.data.img_url;
      // } else {
      //   this.$toast.fail("上传失败：" + result.data?.msg);
      // }
      if (imgUrl) {
        // if (this.isUploaded) {
        //   this.coverIndex = -1;
        //   this.cover.pop();
        //   this.cover.push(imgUrl);
        //   this.coverIndex = this.cover.length - 1;
        // } else {
        //   this.cover.push(imgUrl);
        // }
        // this.isUploaded = true;
        this.cropperImg = imgUrl;
        this.cropperShow = true;
      }
    },
    back() {
      this.$router.back();
    },
    async getInfo() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载配置中...",
      });
      const result = await this.$http.get("/activity/list-scene-new");
      if (result.code == 200) {
        this.cover = result.data.cover;
        this.scene = result.data.scene;
        if (this.editId) {
          this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "加载详情中...",
          });
          const detail = await this.$http.get("/activity/detail", {
            is_my_activity: 1,
          });
          if (detail.code == 200) {
            this.$toast.clear();
            this.title = detail.data.title;
            this.limit_people = detail.data.limit_people;
            this.password = detail.data.password;
            let isCoverCustom = true;
            this.cover.map((i, k) => {
              if (i == detail.data.cover) {
                isCoverCustom = false;
                this.coverIndex = k;
              }
            });
            if (isCoverCustom) {
              this.cover.push(detail.data.cover);
              this.coverIndex = this.cover.length - 1;
              this.isUploaded = true;
            }
            this.startDate = detail.data.start_time.substring(0, 11);
            this.startTime = detail.data.start_time.substring(11, 16);
            this.endDate = detail.data.end_time.substring(0, 11);
            this.endTime = detail.data.end_time.substring(11, 16);
            this.sceneChooseIds = [];
            this.sceneChooseIds = detail.data.scene.map(
              (i) => i.activity_scene_id
            );
            // this.scene.map((i, k) => {
            //   if (sceneIds.includes(i.id)) {
            //     this.sceneIndexs.push(k);
            //   }
            // });
          } else {
            this.$toast.fail("获取配置异常：" + detail.msg);
          }
        } else {
          this.$toast.clear();
        }
      } else {
        this.$toast.fail("获取配置异常：" + result.msg);
      }
    },
  },
};
</script>
<style lang="scss">
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 124px;
  p {
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 52px;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
@media (orientation: portrait) {
  .action-confirm-tips {
    p {
      font-size: 164px;
      line-height: 234px;
      &.tips {
        font-size: 126px;
      }
    }
  }
}
@media (orientation: landscape) {
  .pc {
    .van-calendar__popup {
      width: 1000px !important;
      height: 1450px !important;
      left: 50% !important;
      bottom: 50% !important;
      margin-left: -500px !important;
      margin-bottom: -725px !important;
      border-radius: 60px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  // min-height: 2160px;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  background-size: 100% 100%;
  position: relative;
  .title {
    position: absolute;
    top: 246px;
    left: 280px;
    right: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        &:first-child {
          font-size: 56px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #252727;
          margin-right: 20px;
        }
        &:nth-child(2) {
          font-size: 40px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
          background: linear-gradient(
            270deg,
            #4e54ff 13%,
            #21b4c5 51%,
            #1285d1 91%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .info {
    position: absolute;
    top: 450px;
    left: 280px;
    right: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .info-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 70px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        flex-shrink: 0;
        margin-right: 62px;
        padding-top: 12px;
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .scene-type-name {
        font-size: 40px;
        margin: 10px 0;
        color: #9d9d9d;
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        &.flex-column {
          flex-direction: column;
        }
        &.flex-align-left {
          align-items: flex-start;
        }
        &.overflow-x-hidden {
          overflow-x: hidden;
        }
        &.width-full {
          width: 100%;
        }
        .item-input {
          width: 1462px;
          height: 84px;
          background: transparent;
          border: 1px solid #a8a8a8;
          border-radius: 8px 8px 8px 8px;
          padding: 0 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #333333;
          &::placeholder {
            font-size: 36px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #bbbbbb;
          }
        }
        .item-cover {
          width: 480px;
          height: 280px;
          padding: 10px;
          border-radius: 16px;
          margin-right: 44px;
          box-sizing: border-box;
          position: relative;
          &.active {
            width: 480px;
            height: 280px;
            padding: 10px;
            background: url(#{$PublicEnv}/activity_my/my_activity_scene_bg.png);
            background-size: 100% 100%;
            &::after {
              content: "";
              position: absolute;
              right: 20px;
              top: 25px;
              width: 61px;
              height: 50px;
              background: url(#{$PublicEnv}/activity_my/my_activity_choose.png);
              background-size: 100% 100%;
            }
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
          .icon-upload-wrapper {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_upload.png);
            background-size: 100% 100%;
            background: rgba(250, 250, 252, 1);
            box-sizing: border-box;
            border-radius: 50%;
            position: absolute;
            left: 30px;
            top: 20px;
            .icon-upload {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/activity_my/icon_upload.png);
              background-size: 100% 100%;
            }
          }
        }
        .item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 44px;
          .item-cover {
            margin-right: 0;
          }
          .item-label {
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-top: 28px;
          }
        }
        .item-upload {
          width: 460px;
          height: 258px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: rgba(250, 250, 252, 1);
          border: 1px solid rgba(112, 112, 112, 0.24);
          border-radius: 16px;
          box-sizing: border-box;
          .icon-upload {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_upload.png);
            background-size: 100% 100%;
          }
          span {
            margin-top: 12px;
            font-size: 28px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }
        .time-wrapper {
          margin-right: 20px;
          width: 360px;
          height: 84px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fafafc;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(112, 112, 112, 0.2392);
          box-sizing: border-box;
          padding: 0 20px 0 40px;
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 36px;
            font-family: DIN-Medium, DIN;
            font-weight: 500;
            color: #333333;
          }
          .icon-calendar {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_calendar.png);
            background-size: 100% 100%;
          }
          .icon-time {
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_time.png);
            background-size: 100% 100%;
          }
        }
        .time-split {
          margin-left: 8px;
          margin-right: 28px;
          font-size: 36px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #bbbbbb;
        }
        .icon-minus {
          width: 44px;
          height: 44px;
          background: url(#{$PublicEnv}/activity_my/icon_minus.png);
          background-size: 100% 100%;
        }
        .count-number {
          width: 106px;
          height: 84px;
          background: #fafafc;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(112, 112, 112, 0.2392);
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #999999;
          // margin: 0 24px;
        }
        .icon-add {
          width: 44px;
          height: 44px;
          background: url(#{$PublicEnv}/activity_my/icon_add.png);
          background-size: 100% 100%;
        }
        .icon-tips {
          margin-left: 16px;
          width: 60px;
          height: 60px;
          background: url(#{$PublicEnv}/activity_my/icon_tips.png);
          background-size: 100% 100%;
        }
      }
    }
    .info-action {
      margin-top: 124px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      .back {
        width: 310px;
        height: 80px;
        background: url(#{$PublicEnv}/activity_my/activity_back_bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #4922ec;
        }
      }
      .action {
        margin-left: 60px;
        width: 310px;
        height: 119px;
        background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        padding-top: 14px;
        box-sizing: border-box;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .index {
    padding-bottom: 686px;
    .title {
      top: 845px;
      left: 220px;
      right: 205px;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
          &:first-child {
            font-size: 195px;
            font-weight: 800;
          }
          &:nth-child(2) {
            margin-top: 50px;
            font-size: 195px;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            line-height: 228px;
            text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
            background: linear-gradient(
              270deg,
              #4e54ff 13%,
              #21b4c5 51%,
              #1285d1 91%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
    .info {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      min-height: 6159px;
      margin: 0 102px;
      margin-top: 563px;
      width: auto;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      border-radius: 61px;
      box-shadow: 0 15px 154px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 1060px 120px 100px 120px;
      .info-item {
        flex-direction: column;
        &:last-child {
        }
        .left {
          margin-bottom: 154px;
          span {
            font-size: 164px;
          }
        }
        .scene-type-name {
          font-size: 140px;
          margin: 30px 0;
        }
        .right {
          width: 100%;
          flex-wrap: wrap;
          .item-input {
            width: 90%;
            height: 307px;
            border: 3px solid #a8a8a8;
            border-radius: 20px;
            font-size: 164px;
            padding-left: 80px;
            &::placeholder {
              font-size: 164px;
            }
          }
          .item-wrapper {
            .item-label {
              margin-top: -40px;
              font-size: 143px;
            }
          }
          .item-cover {
            width: 1613px;
            height: 937px;
            padding: 36px;
            margin-bottom: 133px;
            margin-right: 80px;
            border-radius: 51px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &.active {
              width: 1613px;
              height: 937px;
              padding: 36px;
              &::after {
                width: 305px;
                height: 250px;
                right: 100px;
                top: 102px;
              }
            }
            img {
              border-radius: 51px;
            }
            .icon-upload-wrapper {
              width: 246px;
              height: 256px;
              left: 150px;
              top: 100px;
              .icon-upload {
                width: 246px;
                height: 256px;
              }
            }
          }
          .item-upload {
            width: 1613px;
            height: 937px;
            padding: 36px;
            .icon-upload {
              width: 246px;
              height: 256px;
            }
            span {
              font-size: 164px;
            }
          }
          .time-wrapper {
            width: 1398px;
            height: 307px;
            margin-bottom: 133px;
            margin-right: 36px;
            justify-content: center;
            border-radius: 26px;
            &:last-child {
            }
            span {
              font-size: 164px;
            }
            .icon-calendar {
              width: 164px;
              height: 169px;
              margin-left: 97px;
            }
            .icon-time {
              width: 205px;
              height: 205px;
              margin-left: 97px;
            }
          }
          .time-split {
            font-size: 164px;
            margin-bottom: 133px;
          }
          .icon-minus {
          }
          .count-number {
            width: 538px;
            height: 307px;
            font-size: 164px;
          }
          .icon-add {
          }
          .icon-tips {
            margin-left: 30px;
            width: 240px;
            height: 240px;
            background: url(#{$PublicEnv}/activity_my/icon_tips.png);
            background-size: 100% 100%;
          }
        }
      }
      .info-action {
        .back {
          width: 1485px;
          height: 369px;
          span {
            font-size: 195px;
          }
        }
        .action {
          margin-left: 77px;
          width: 1485px;
          height: 550px;
          padding-top: 50px;
          span {
            font-size: 195px;
          }
        }
      }
    }
  }
}
</style>
